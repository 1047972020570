/* eslint-disable no-array-constructor */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from 'react'
import { getAllGammeDay, getAllGammeMonth, getAllGammeYears } from '../../services/Objectifs/ObjectifApi'
import { Pie, Doughnut } from 'react-chartjs-2';
import ErrorAlert from '../../others/ErrorAlert';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import moment from 'moment';

export default function MonthGamme() {
    //Liste gamme today & Month pour la représentation en diagramme 

  
    //Error Alert
    const [error, seterror] = useState(false)
    const language = Cookies.get("locale");
    //Traduction
    const [t, i18n] = useTranslation("global");
    //ITEMS GRAPHE MONTH
    const [label, setlabel] = useState([])
    const [data, setdata] = useState([])
    const [color, setcolor] = useState([])
    //ITEMS GRAPHE DAY
    const [label1, setlabel1] = useState([])
    const [data1, setdata1] = useState([])
    const [color1, setcolor1] = useState([])
    //ITEMS GRAPHE years
    const [label2, setlabel2] = useState([])
    const [data2, setdata2] = useState([])
    const [color2, setcolor2] = useState([])

    useEffect(() => {

        getAllGammeMonth()
            .then((res) => {
                //setgamme(res?.data)
                const Labels = []
                const Data = []
                const BackgroundColor = []
                res?.data?.map((items, keys) => {
                    Labels.push(items.type + ", " + t('topgamme.casier') + " (" + items.casier + ")")
                    Data.push(items.percent)
                    BackgroundColor.push(items.color)
                })
                setlabel(Labels)
                setdata(Data)
                setcolor(BackgroundColor)
            }).catch((error) => {
                console.log(error)
            })

        getAllGammeYears()
            .then((res) => {
                //setgamme(res?.data)
                const Labels = []
                const Data = []
                const BackgroundColor = []
                res?.data?.map((items, keys) => {
                    Labels.push(items.type + ", " + t('topgamme.casier') + " (" + items.casier + ")")
                    Data.push(items.percent)
                    BackgroundColor.push(items.color)
                })
                setlabel2(Labels)
                setdata2(Data)
                setcolor2(BackgroundColor)
            }).catch((error) => {
                console.log(error)
            })
        //CALLING WEB SERVICE TO GET ALL GAMME BY DAY
        getAllGammeDay().then((res) => {
            // setgammeday(res?.data)
            const Labels = []
            const Data = []
            const BackgroundColor = new Array()
            res?.data?.map((items, keys) => {
                Labels.push(items.type + ", " + t('topgamme.casier') + " (" + items.casier + ")")
                Data.push(items.percent)
                BackgroundColor.push(items.color)
            })
            setlabel1(Labels)
            setdata1(Data)
            setcolor1(BackgroundColor)
        }).catch((error) => {
            console.log(error)
        })

    }, [])

    return (
        <div>
           
                    <div className="row">
                        <div className='col-md-6 col-sm-6 col-lg-6 col-12'>
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        {t('topgamme.venteaujourdhui')}
                                    </div>
                                </div>
                                <div className="card-body">

                                        <div className="row">
                                            {label1.length === 0 && data1.length === 0 ? (
                                                <div className="col-md-12 col-xl-12 col-lg-12  text-center">
                                                    <img
                                                        src="../assets/images/photos/nodata.png"
                                                        alt="img"
                                                        max-height="70%"
                                                    />
                                                </div>
                                            ) : (
                                                <Pie data={{
                                                        labels: label1,
                                                        datasets: [
                                                            {
                                                                data: data1,
                                                                backgroundColor: color1,
                                                                borderColor: color1,
                                                            }
                                                        ] }}
                                                    height={400}
                                                    width={600}
                                                    options={{
                                                        maintainAspectRatio: false,
                                                        responsive: true,
                                                        title: {
                                                            display: true,
                                                            text: 'Ventes par gamme mois Septembre'
                                                        }
                                                    }}
                                                />
                                            )}
                                        </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-6 col-lg-6 col-12'>
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        {language === "ar" ? (
                                            <p className="mb-0 fs-16">

                                                {t('topgamme.ventemois')}({moment().format("MMMM")})
                                            </p>
                                        ) : (
                                            <p className="mb-0 fs-16">
                                                {t('topgamme.ventemois')} ({moment().format("MMMM")})
                                            </p>
                                        )}

                                    </div>
                                </div>
                                <div className="card-body">
                                    <Pie
                                        data={{
                                            labels: label,
                                            datasets: [
                                                {
                                                    data: data,
                                                    backgroundColor: color,
                                                    borderColor: color,
                                                }
                                            ]
                                        }}
                                        height={400}
                                        width={600}
                                        options={{
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            title: {
                                                display: true,

                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-md-12 col-sm-12 col-lg-12 col-12'>
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-title">
                                        {t('topgamme.ventemois')}({moment().format("YYYY")})
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Doughnut
                                        data={{
                                            labels: label2,
                                            datasets: [
                                                {
                                                    data: data2,
                                                    backgroundColor: color2,
                                                    borderColor: color2,
                                                }
                                            ]
                                        }}
                                        height={400}
                                        width={600}
                                        options={{
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            title: {
                                                display: true,

                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
         
            {error ? <ErrorAlert t={t} /> : <></>}
        </div>
    )
}
