import axios from "axios";

const url = localStorage.getItem("url_nomadis");

const instance = axios.create({
  baseURL: url,
  withCredentials: true,
  /*   SameSite: "None",
    Secure: true, */
  IsEssential: true,
});
/**
 * GET COLONES
 * @returns
 */
export async function getColonne() {
  try {
    const response = await instance.get(
      "/veuilles/getColonne"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * GET LIGNES
 * @returns
 */
export async function getLignes() {
  try {
    const response = await instance.get(
      "/veuilles/getLignes"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * POST response
 */
export async function saveVeille(data) {
  try {
    const response = await instance.post(
      "/veuilles/postAll", data
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}

/***
 * get client passager
 */
export async function getClientPassager() {
  try {
    const response = await instance.get(
      "clients/getPassager"
    );
    return response;
  } catch (error) {
    console.error(error);
  }
}