/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getCurrentDate, getCurrentMonth, getMonthNameCmd, getMonthNameFrensh } from "../../../utils/DateUtils";
import { numberWithSpaces } from "../../../utils/NumberWithSpaces";
import ErrorAlert from "../../../others/ErrorAlert";
import moment from "moment";
import { toast } from "react-toastify";
import SquareLoader from "../../../components/Loader/SquareLoader";
import { getObjectifVente, getVentesByDates } from "../../../services/ventes/venteApi";
import { getUserListes } from "../../../services/clients/ClientApi";

export default function CardsVente({ t }) {
  //DATE TODAY
  const today = getCurrentDate();
  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  var date1 = firstDay.getFullYear() + "-" + (firstDay.getMonth() + 1) + "-" + firstDay.getDate()
  var date2 = lastDay.getFullYear() + "-" + (lastDay.getMonth() + 1) + "-" + lastDay.getDate()
  //DATE OF YEAR 
  var firstDay1 = new Date(date.getFullYear(), 0, 1);
var lastDay1 = new Date(date.getFullYear(), 11, 31);
var date11 = firstDay1.getFullYear() + "-" + (firstDay1.getMonth() + 1) + "-" + firstDay1.getDate();
var date22 = lastDay1.getFullYear() + "-" + (lastDay1.getMonth() + 1) + "-" + lastDay1.getDate();

  //STATE

  // les ventes 
  const [InfoVenteMonth, setInfoVenteMonth] = useState({})
  const [InfoVenteDay, setInfoVenteDay] = useState({})
  const [InfoVenteYear, setInfoVenteYear] = useState({})

  //Objectif vente
  const [objVente, setObjVente] = useState("00"); //annee
  const [objVenteMonth, setObjVenteMonth] = useState(0); // Month
  //Commercial actif operant
  const [countOperant, setCountOperant] = useState(0)
  //Values
  const [valueMonth, setValueMonth] = useState(0);
  const [valueMonthCmd, setValueMonthCmd] = useState(0);


  //ERROR
  const [error, setError] = useState(false);
  //Loader
  const [loading, setLoading] = useState(true);
  const [loadingYears, setloadingYears] = useState(true);


  useEffect(() => {

  

    // 1- GET VENTE BY DAY & MONTH
    getVentesByDates(date1, date2).then((res) => {
      if (res === false) {
        toast(t('widget.cnxinternet'), {
          type: "warning", position: "bottom-center"
        });
        setError(true)
      }
      else if (res === 401) {
        toast(t('messages.autorisation'), {
          type: "warning", position: "bottom-center"
        });
      }
      else if (res?.data.length !== 0) {
        setInfoVenteMonth(res?.data[0])
      }
      setLoading(false)
    })
    .catch((error) => console.log(error))

    getVentesByDates(today, today)
      .then((res) => {
        if (res === false) {
          toast(t('widget.cnxinternet'), {
            type: "warning", position: "bottom-center"
          });
          setError(true)
        }
        else if (res === 401) {
          toast(t('messages.autorisation'), {
            type: "warning", position: "bottom-center"
          });
        }
        else if (res?.data.length !== 0) {
          setInfoVenteDay(res?.data[0])
        }
        setLoading(false)
      })
      .catch((error) => console.log(error))

      //GET vente BLS BY YEARS
      getVentesByDates(date11, date22)
        .then((res) => {
          if (res === false) {
            toast(t('widget.cnxinternet'), {
              type: "warning", position: "bottom-center"
            });
            setError(true)
          }
          else if (res === 401) {
            toast(t('messages.autorisation'), {
              type: "warning", position: "bottom-center"
            });
          }
          else if (res?.data.length !== 0) {
            setInfoVenteYear(res?.data[0])
          }
          setLoading(false)
        })
        .catch((error) => console.log(error))
 
      /* axios.get(url + `entetecommercial/getChiffre`, {
        withCredentials: true,
        SameSite: "None",
        secure: true,
        IsEssential: true,
      })
      .then((res) => {
        if (res === undefined) {
          //Verifier 
        }
        if (res?.data?.length !== 0) {

          //setVenteYears(res?.data?.bls[0].net_a_payer);
        }
      })
      .catch((error) => {
        console.log(error)
      }); */
    //GET OBJECTIF VENTE
    getObjectifVente()
      .then((res) => {
        const result = res.data;
        //Calcul Objectif Annee
        const obj_annee =
          ((result.r_janvier +
            result.r_fevrier +
            result.r_mars +
            result.r_avril +
            result.r_mai +
            result.r_juin +
            result.r_juillet +
            result.r_aout +
            result.r_septembre +
            result.r_octobre +
            result.r_novembre +
            result.r_decembre +
            result.r_decembre) *
            100) /
          (result.janvier +
            result.fevrier +
            result.mars +
            result.avril +
            result.mai +
            result.juin +
            result.juillet +
            result.aout +
            result.septembre +
            result.octobre +
            result.novembre +
            result.decembre +
            result.decembre);
        setObjVente(obj_annee);
        //Calcul Objectif Month
        const month_fr = getMonthNameFrensh(getCurrentMonth());
        const month_cmd = getMonthNameCmd(getCurrentMonth());
        const date = Object.entries(result);
        // eslint-disable-next-line array-callback-return
        date.map((items, keys) => {
          if (date[keys][0] === month_cmd) {
            setValueMonthCmd(date[keys][1]);
          }
          if (date[keys][0] === month_fr) {
            setValueMonth(date[keys][1]);
          }
        })
        
      })
      .catch((error) => console.log(error)).finally(()=>{
        setloadingYears(false)
      })     

      getUserListes()
      .then((res) => {
        if (res.data.users !== null) {
          const total = res?.data.users
            .filter((element) => element.isactif === 1 && element.role_code === "commercial")
            .reduce((totalsum) =>
              totalsum + 1,
              0
            );
          setCountOperant(total)
        }

      })

  }, []);

  useEffect(() => {
    if (valueMonth !== 0) {
      const calcul_Month = (valueMonthCmd * 100) / valueMonth;
      setObjVenteMonth(calcul_Month);
    } else {
      setObjVenteMonth(0);
    }
  }, [valueMonth, valueMonthCmd]);

  return (
    <div className="row">

      {/** VENTE AUJOURDHUI */}
      <div className=" col-md-4 col-lg-4 col-xl-4">
        <div className="card overflow-hidden">
          <div className="card-body">
            {loading ? <SquareLoader /> : (
              <>
                <h4 className="card-title mb-4 text-primary">
                  {t("suivi.venteaujourdhui")}  <b className="float-right">Tot.Qte: {InfoVenteDay?.total_quantite}</b>
                </h4>
                <div className="d-flex  align-items-center">
                  <h4 className=" mb-0">
                    {isNaN(parseFloat(InfoVenteDay?.net_a_payer)) ? 0.00 : numberWithSpaces(parseFloat(InfoVenteDay?.net_a_payer).toFixed(3))}
                  </h4>
                  <div className="text-primary mt-0 mb-2  ml-auto">
                    <i
                      className="icon icon-chart  fa-3x overflow-hidden mx-auto"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                <p className="text-muted  mb-4  ">
                  <span className="font-weight-normal text-muted fs-13" style={{ marginTop: '10px' }}>
                    {t('widget.comercialoperant') + " : " + countOperant}
                  </span>
                  <div className="float-right">Tot.Poids : {InfoVenteDay?.total_poids}  g</div>
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {/** VENTE MONTH */}
      <div className=" col-md-4 col-lg-4 col-xl-4">
        <div className="card overflow-hidden">
          <div className="card-body">
            {loading ? <SquareLoader /> : (
              <>
                <h4 className="card-title mb-4 text-primary">
                  {t('ventes.ventes') + " (" + moment().format("MMMM") + ")"} <b className="float-right">Tot.Qte: {InfoVenteMonth?.total_quantite}</b>
                </h4>
                <div className="d-flex  align-items-center">
                  <h4 className=" mb-0">
                  {isNaN(parseFloat(InfoVenteMonth?.net_a_payer)) ? 0.00 : numberWithSpaces(parseFloat(InfoVenteMonth?.net_a_payer).toFixed(3))}
                  </h4>
                  <div className="text-primary mt-0 mb-2  ml-auto">
                    <i
                      className="fe fe-trending-up  fa-3x overflow-hidden mx-auto"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                <p className="text-muted  mb-4  ">
                  <span className="font-weight-normal text-muted fs-13">
                    {parseFloat(objVenteMonth) >= 100
                      ? "100 ٪"
                      : (isNaN(objVenteMonth) ? 0.00 + " ٪ " : parseFloat(objVenteMonth).toFixed(2) + " ٪ ")
                    }
                    {" " + t("dashboard.objectif") + " " + moment().format("MMMM")} 
                    <div className="float-right">Tot.Poids : {InfoVenteMonth?.total_poids} g</div>
                  </span>

                </p>
              </>
            )}
          </div>
        </div>
      </div>

      {/**VENTE PAR ANNEE */}
      <div className=" col-md-4 col-lg-4 col-xl-4">
        <div className="card overflow-hidden">
          <div className="card-body">
            {loadingYears ? <SquareLoader /> : (
              <>
                <h4 className="card-title mb-4 text-primary">
                  {t('ventes.ventes') + " (" + moment().format("YYYY") + ")"} <b className="float-right">Tot.Qte: {InfoVenteYear?.total_quantite}</b>
                </h4>
                <div className="d-flex  align-items-center">
                  <h4 className=" mb-0">
                  {isNaN(parseFloat(InfoVenteYear?.net_a_payer)) ? 0.00 : numberWithSpaces(parseFloat(InfoVenteYear?.net_a_payer).toFixed(3))}
                  </h4>
                  <div className="text-primary mt-0 mb-2  ml-auto">
                    <i
                      className="fe fe-server  fa-3x overflow-hidden mx-auto"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                <p className="text-muted  mb-4  ">
                  <span className="font-weight-normal text-muted fs-13">
                    {parseFloat(objVente) >= 100 ? 100 + " ٪ "
                      :
                      (isNaN(objVente) ? 0.00 + " ٪ " : parseFloat(objVente).toFixed(2) + " ٪ ")}
                    {" " + t("dashboard.objectif") + " (" + moment().format("YYYY") + ") "}
                    <div className="float-right">Tot.Poids : {InfoVenteYear?.total_poids} g</div>
                  </span>
                </p>
              </>
            )}

          </div>
        </div>
      </div>

      {error ? <ErrorAlert t={t} /> : <></>}
    </div>
  );
}
