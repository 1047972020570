import { BrowserRouter, Switch } from "react-router-dom";
import AppRoute from "./routes/AppRoute";
import PrivateRoute from "./routes/PrivateRoute";
import Code from "./components/Authenticator/Code";
import EmptyLayout from "./layouts/EmptyLayout";
import PageNotFound from "./pages/PageNotFound";
import SignIn from "./components/Authenticator/Login";
import ListCommandes from "./pages/commandes/pageCommande/ListCommandes";
import ListVentes from "./pages/ventes/pageVente/ListVentes";
import AccessClient from "./pages/client/AccessClient";
import ClientInformations from "./pages/client/ClientInformations";
import PrivateRouteClient from "./routes/PrivateRouteClient";
import OthersPages from "./layouts/OthersPages";
import Stock from "./pages/TodayCommercial/stock/Stock";
import Releve from "./pages/client/releve/Releve";
import DetailRelevé from "./pages/client/releve/DetailRelevé";
import Historiques from "./pages/client/historique/Historiques";
import ListeTournee from "./pages/tournee/ListeTournee";
import DetailsTournee from "./pages/tournee/DetailsTournee";
import Objectifs from "./pages/Objectifs/Objectifs";
import StockTotal from "./pages/TodayCommercial/stock/StockTotal";
import DetailsBl from "./pages/TodayCommercial/DetailsBl";
import TxSucee from "./pages/client/txsuccee/TxSucee";
import ListeCommercialToday from "./pages/TodayCommercial/Commandes/ListeCommercialToday";
import MapVisites from "./pages/TodayCommercial/visites/MapVisites";
import ListeCommercialTodayVente from "./pages/TodayCommercial/Ventes/ListeCommercialTodayVente";
import MonthGamme from "./pages/TopGamme/MonthGamme";
import DemancheConge from "./pages/Conge/DemancheConge";
import VenteMain from "./pages/Dashboard/VenteMain";
import ListeNotes from "./pages/Notifications/ListeNotes";
import DemandeChargement from "./pages/Notifications/DemandeChargement";
import DetailChargement from "./pages/Notifications/DetailChargement";
import Reportingcommercial from "./pages/Reporting/ReportingCommercial/Reportingcommercial";
import Top10client from "./pages/Reporting/ReportingCommercial/Top10client";
//import Reportingvisites from "./pages/Reporting/ReportingVisites/Reportingvisites";
import Suivicommercial from "./pages/Reporting/SuiviCommercial/Suivicommercial";
import ChequierCalendar from "./pages/Calendrier/ChequierCalendar";
import ListeCommandes from './pages/Notifications/ListeCommandes'
import AjouterClient from "./pages/client/AjouterClient/AjouterClient";
import ListeClients from "./pages/client/ListeClient/ListeClients"
import EntretienVehicule from "./pages/Vehicule/EntretienVehicule";
import DetailsVehicule from "./pages/Vehicule/Details/DetailsVehicule";
import PageInventaire from "./pages/Inventaire/PageInventaire";
import SuiviCommClient from "./pages/SuiviCommercialClient/SuiviCommClient";
import GestionCommandes from "./pages/commandes/GestionCommandes/GestionCommandes"
import LivraisonInterface from "./pages/commandes/GestionCommandes/LivraisonInterface";
import DetailsCamions from "./pages/commandes/GestionCommandes/DetailsCamions";
import Veille from "./pages/Veille/Veille";
import Rapport from "./pages/rapport/Rapport";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <AppRoute layout={EmptyLayout} component={Code} path="/code" />

        <AppRoute layout={EmptyLayout} component={SignIn} path="/login" />
        <PrivateRoute
          layout={OthersPages}
          component={VenteMain}
          path="/"
          exact
        />
        <PrivateRoute layout={OthersPages} component={Veille} path="/Veille/:code?" />
        <PrivateRoute layout={OthersPages} component={Rapport} path="/Rapport" exact />

        <PrivateRoute
          layout={OthersPages}
          component={ListCommandes}
          path="/commandes"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={GestionCommandes}
          path="/gestion_commandes/date=:date"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={DetailsCamions}
          path="/details_camions/camion=:codecamion&date=:date"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={LivraisonInterface}
          path="/livraison/bl=:bl&date=:date"
          exact
        />

        <PrivateRoute
          layout={OthersPages}
          component={AjouterClient}
          path="/ajouter_client"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ListeClients}
          path="/liste_client"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ListeCommandes}
          path="/liste_commandes"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={Stock}
          path="/stocks/:code"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={StockTotal}
          path="/total_stock"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ChequierCalendar}
          path="/Calendrier"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ListVentes}
          path="/ventes"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ListeNotes}
          path="/notes/:id"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={MonthGamme}
          path="/top_gammes"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={DemancheConge}
          path="/conge"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={DemandeChargement}
          path="/demande_chargement"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={DetailChargement}
          path="/chargement/:code"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ListeCommercialToday}
          path="/Aujourdhui_commandes"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={EntretienVehicule}
          path="/code_vehicule"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={DetailsVehicule}
          path="/details_vehicule"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={PageInventaire}
          path="/liste_inventaire"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ListeCommercialTodayVente}
          path="/Aujourdhui_ventes"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={Objectifs}
          path="/objectifs"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={DetailsBl}
          path="/detailsbl/:code"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={MapVisites}
          path="/detailVisite/:code"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={Reportingcommercial}
          path="/reportingCommercial/commercial=:commercial&datedebut=:debut&datefin=:fin"
          exact
        />

        <PrivateRoute
          layout={OthersPages}
          component={Top10client}
          path="/Top10client/commercial=:commercial&datedebut=:debut&datefin=:fin"
          exact
        />



        <PrivateRoute
          layout={OthersPages}
          component={Suivicommercial}
          path="/suivicommercial"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={SuiviCommClient}
          path="/suiviCommercial/commercial=:commercial&month=:month"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ListeTournee}
          path="/tournee"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={ListeTournee}
          path="/tournee"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={DetailsTournee}
          path='/tournee/:routing_code/:tournee_code/:usercode/:codejour'
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={AccessClient}
          path="/code_client"
          exact
        />
        <PrivateRouteClient
          layout={OthersPages}
          component={ClientInformations}
          path="/clients"
          exact
        />
        <PrivateRouteClient
          layout={OthersPages}
          component={TxSucee}
          path="/txSuccee"
          exact
        />
        <PrivateRouteClient
          layout={OthersPages}
          component={Releve}
          path="/releve/:code"
          exact
        />
        <PrivateRoute
          layout={OthersPages}
          component={DetailRelevé}
          path="/detail_releve/:code"
          exact
        />
        <PrivateRouteClient
          layout={OthersPages}
          component={Historiques}
          path="/historique/:code"
          exact
        />
        <AppRoute layout={EmptyLayout} component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
